
import { Vue, Component, Watch } from "vue-property-decorator";
import { CentroCusto } from '@/core/models/financeiro';
import { CentroCustoService } from '@/core/services/financeiro';

@Component
export default class ListaCentroCusto extends Vue {
  item = new CentroCusto();
  service = new CentroCustoService();
  loading: boolean = false;
  dialogCadastro: boolean = false;
  sheet:boolean = false;

initiallyOpen= ['RAIZ'];

  filter : {
    id: number,
  } = {
    id:0 ,
  }

  Consultar(){
    if(this.filter.id > 0){

    this.options.page = 1;
    
    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
    this.loading = true
    this.service.ListarComFiltro(page,itemsPerPage,sortBy,sortDesc,search,columns,this.filter, '').then(
    
    (res) => { 
      let treeAux = [{ id: -1, codigoDescricao: 'RAIZ',children: []}];
      treeAux[0].children = res.data.items;
      this.tree = treeAux;  
 

      this.totalLista = res.data.count;
      this.loading = false; 
     },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }); 
    }else{
      this.listar();
    }
    this.sheet = false;
  }

  ResetFiltro(){
    this.filter = {id:0}
  }

  editedItem:any = new CentroCusto();
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  dialog: boolean = false;
  valid: boolean = true;
  search: string = "";
  nomePai: string = "";
  editedIndex: number = -1;

  public localBuscaRapida: string = "";
  public titulo: string = 'Centro custo';
  public subTitulo: string = 'Centro custo cadastrados no Sistema';
  public temBuscaRapida: boolean = true;
  public icone: string = 'mdi-cash';

  totalLista: number = 0;
  lista: any[] = [];

  tree: any[] = [];
  open: any[0] = [];
  active: any[] = [];

  $refs!: {
    form: HTMLFormElement
  }

  @Watch('item') 
  Item(){
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }


  options: any = { itemsPerPage: -1};

  close() {
    this.dialog = false;
  }
  
  pai: string = "";

  addItem(item: CentroCusto) {
      let pai = Object.assign({}, this.lista.find(x => x.id === this.active[0]),item);
      
      this.editedItem = new CentroCusto();
      this.editedIndex = -1
      this.editedItem.codigo ='';
      this.editedItem.centroCustoPaiId = pai.id === -1 ? null : pai.id;
      this.editedItem.ativo = true;
      this.nomePai = pai.codigoDescricao;
      this.dialog = true;
  }

  listar(){ 
    this.loading = true;
    this.service.TreeView()
        .then(
            res => {
              let treeAux = [{ id: -1,codigoDescricao: 'RAIZ',children: []}];
              treeAux[0].children = res.data;
              this.tree = treeAux;         
              this.loading = false;
            },
            err => this.$swal('Aviso', err.response.data, 'error')
        );
        this.service.ListarTudo()
          .then( 
            res => {
                let aux = {
                    id: -1,
                    codigoDescricao: 'RAIZ',
                };
                this.lista = res.data.items;
                this.lista.unshift(aux);
            },
          (err) => {
            if (!err.response) {
              this.$swal("Aviso", "Não foi possível acessar a API", "error");
            } else if (err.response.status == 403) {
              this.$swal("Aviso", err.response.data.message, "warning" );
            } else {
              this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
            }
          }); 
    }
 
  Excluir(item: CentroCusto) {
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.service.Excluir(item.id).then(
          (res) => {
            if (res.status == 200) {
              return res;
            }
          },
          (err) => this.$swal("Aviso", err.response.data, "error")
        );
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result:any) => {
      if (result.value) {
        this.$swal(
          "Aviso",
          result.value.data,
          result.value.status == 200 ? "success" : "warning"
        );
         this.listar();
      }
    });
  }
  
  Editar(item:CentroCusto) {   
      this.editedIndex = 1;
      let pai = this.lista.find(x => x.id === item.centroCustoPaiId);
      this.editedItem = item
      this.nomePai = pai ? pai.codigoDescricao : 'RAIZ';
      this.dialog = true;
  }

  save() {
    if(this.$refs.form.validate()){
      
      let modelo = this.editedItem;
      this.service.Salvar(modelo)
      .then(
          res => {
            this.$swal('Aviso', 'Operação realizada com sucesso!', res.status == 201 || res.status == 200 ? 'success' : 'warning');
              if (res.status == 201 || res.status == 200){
                  this.listar();
              }
            }, 
            (err) => {
              if (!err.response) {
                this.$swal("Aviso", "Não foi possível acessar a API", "error");
              } else {
                this.$swal("Aviso", err.response.data, err.response.status == 400 ? "warning" : "error");
              }
            }
        )
    this.close()
    }
  }
   
  mounted(){
    this.listar();
  }
}
